<template>
  <!-- HORIZONTAL LAYOUT -->
  <vx-card title="Create Product B2B">
    <div class="vx-row mb-6" style="width: 50%">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
        >Back</vs-button
      >
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-base">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Territory</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedTerritory"
              :options="optionTerritory"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="100"
              :limit="3"
              :disabled="disableTerritory"
              placeholder="Type to search"
              :searchable="true"
              :custom-label="customLableTerritory"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Supplier</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedSupplier"
              :options="optionSupplier"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="100"
              :limit="3"
              :disabled="disableTerritory"
              placeholder="Type to search"
              :searchable="true"
              :custom-label="customLableSupplier"
              @input="SupplierInput"
            />
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Valid From</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-input
              type="date"
              class="w-full"
              v-validate="'required'"
              name="ValidFrom"
              v-model="validity.startDate"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('ValidFrom')"
              >{{ errors.first("ValidFrom") }}</span
            >
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Valid To</span>
          </div>
          <div class="vx-col sm:w-2/5 w-full">
            <vs-input
              type="date"
              class="w-full"
              v-validate="'required'"
              name="ValidTo"
              v-model="validity.endDate"
            />
            <span class="text-danger text-sm" v-show="errors.has('ValidTo')">{{
              errors.first("ValidTo")
            }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 flex-direction-row">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Min. Order (SO)</span>
          </div>
          <div class="vx-col sm:w-1/5 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedUnit"
              :options="optionalUnit"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="100"
              :limit="3"
              placeholder="Type to search"
              :searchable="true"
              :custom-label="customLableUnit"
            />
          </div>
          <div class="vx-col sm:w-1/5 w-full">
            <vs-input class="w-full" type="number" v-model="qtySO" :min="1" />
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 60%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Status</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-switch width="120px" color="success" v-model="status">
              <span slot="on">Active</span>
              <span slot="off">Inactive</span>
            </vs-switch>
          </div>
        </div>
        <br />
        <hr />
        <br />
        <div class="vx-row">
          <div class="vx-col sm:w-1/1 w-full mb-base m-10">
            <table width="100%" class="vs-table vs-table--tbody-table">
              <thead class="vs-table--thead">
                <tr>
                  <th width="30%">SKU Code</th>
                  <th width="30%">SKU Name</th>
                  <th width="30%">HU</th>
                  <th width="30%">Min. Qty [SKU]</th>
                  <th width="5%"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(child, index) in itemLines"
                  :key="index"
                  style="padding-bottom: 5px"
                >
                  <td class="td vs-table--td" style="padding: 5px">
                    <multiselect
                      class="selectExample"
                      v-model="itemLines[index].selected_items"
                      :options="optionItem"
                      :multiple="false"
                      :allow-empty="false"
                      :group-select="false"
                      :max-height="160"
                      :limit="4"
                      placeholder=" Type to search"
                      track-by="item_id"
                      label="sku_code"
                      @search-change="onSearchItems($event, true)"
                      :custom-label="customLableItems"
                    />
                  </td>
                  <td class="td vs-table--td" style="padding: 5px">
                    <span>
                      {{
                        itemLines[index].selected_items
                          ? itemLines[index].selected_items.item_name
                          : ""
                      }}
                    </span>
                  </td>

                  <td class="td vs-table--td" style="padding-right: 10px">
                    <multiselect
                      v-if="itemLines[index].selected_items"
                      class="selectExample"
                      v-model="itemLines[index].selected_item_unit"
                      :options="
                        itemLines[index].selected_items.hu_item_response
                      "
                      :multiple="false"
                      :allow-empty="false"
                      :group-select="false"
                      :max-height="160"
                      :limit="10"
                      placeholder=" Type to search"
                      track-by="unit_id"
                      label="unit_name"
                    />
                  </td>

                  <td class="td vs-table--td" style="padding-right: 10px">
                    <vs-input
                      class="w-full"
                      type="number"
                      v-model="itemLines[index].qty"
                      :min="1"
                    />
                  </td>

                  <td
                    width="75px"
                    class="td vs-table--td"
                    style="text-align: center"
                  >
                    <div class="vx-input-group flex">
                      <vs-button
                        v-if="itemLines.length > 1"
                        @click.stop="removeRow(index)"
                        size="small"
                        color="danger"
                        icon-pack="feather"
                        icon="icon-trash"
                        style="margin-right: 5px"
                        title="Remove Row"
                      />

                      <vs-button
                        v-if="index == itemLines.length - 1"
                        @click.stop="addRow(index)"
                        size="small"
                        color="success"
                        icon-pack="feather"
                        icon="icon-plus"
                        title="Add Row"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-6/12 w-full m-10" v-if="update">
            <vs-button class="mr-3 mb-2" v-on:click="SubmitForm()"
              >Submit</vs-button
            >
            <!-- <vs-button v-if="quantityChecked != true" class="mr-3 mb-2" v-on:click="CheckQty()">CheckQty</vs-button> -->
            <vs-button color="danger" class="mr-3 mb-2" @click="CancelForm()"
              >Cancel</vs-button
            >
          </div>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import ArrowUpDownBold from "vue-material-design-icons/ArrowUpDownBold.vue";
import PlusBox from "vue-material-design-icons/PlusBox.vue";

import draggable from "vuedraggable";

import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  components: {
    draggable,
    ArrowUpDownBold,
    PlusBox,
    Datepicker,
    vSelect,
    DateRangePicker,
  },
  mounted() {
    // console.log("mounted - form : ", this.territoryCode, this.territoryId, this.selectedReservation, this.optionTerritory);
    this.update = true;
    this.getTerritory();
    this.getUnit();
    this.getSupplier();
    // this.getOptionItemAvailable();
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  watch: {
    search: function () {
      if (this.optionItemAvailableShow) {
        this.getOptionItemAvailable();
      }
    },
    itemsQuantity: function () {
      this.quantityChecked = false;
    },
  },
  data: () => ({
    update: false,
    quantityChecked: false,
    external: false,
    optionItem: [],
    itemLines: [
      {
        selected_items: null,
        option_item_unit: [],
        selected_item_unit: null,

        qty: [],
      },
    ],

    optionCustomerShow: false,
    optionItemAvailableShow: false,

    limits: [10, 25, 50, 100, "All"],
    limitShow: 10,
    drawData: 0,
    isActive: 1,
    recordsTotal: 0,
    maxPage: 2,
    dataEnd: 0,
    disableTerritory: false,

    optionTerritory: [],
    selectedTerritory: {
      id: 0,
      code: "",
      name: "",
    },
    selectedSupplier: { id: 0, code: "", name: "" },
    optionSupplier: [],
    selectedUnit: {
      id: 0,
      Name: "",
    },
    optionalUnit: [],
    start: 1,
    end: 0,
    length: 10,
    page: 1,
    search: "",
    order: "id",
    sort: "desc",
    total: 0,
    totalPage: 0,
    totalSearch: 0,
    validity: {
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    },
    minValidFrom: null,
    maxValidTo: null,
    CompanyID: 0,
    qtySO: 0,
    status: true,
    type: 1,
  }),
  methods: {
    handleBack() {
      this.$router.push({
        name: "product-back-to-back",
      });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    containsKey(obj, key) {
      return Object.keys(obj).includes(key);
    },
    CancelForm() {
      this.update = true;
      this.quantityChecked = false;
      this.external = false;

      this.selectTerritory = {};
      this.selectedSupplier = {};
      this.selectedUnit = {};

      this.itemUnitID = [];
      this.itemLines = [
        {
          selected_items: null,
          option_item_unit: [],
          selected_item_unit: null,
          qty: [],
        },
      ];
      this.disableTerritory = false;
      this.optionItemAvailableShow = false;

      this.$emit("selectReservation", {});
    },
    getOptionItemAvailable(index) {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/product-btwob-rules/all-item-unit-supplier", {
          params: {
            territory_id: this.selectedTerritory.id,
            supplier_id: this.selectedSupplier.id,
            length: this.length,
            page: this.page,
            search: this.search,
            order: this.order,
            sort: this.sort,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionItem = resp.data.records;
            console.log("optionItem", this.optionItem);
            console.log("itemLines", this.itemLines);

            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Item Available",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    SubmitForm() {
      if (this.itemLines.length == 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please select Item Line first",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      }
      if (!this.validity.startDate || !this.validity.endDate) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Valid from and valid to is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }

      //check data line , if item_unit_id is duplicate then show error
      let cek = this.itemLines.filter((el, index) => {
        let cek2 = this.itemLines.filter((el2, index2) => {
          if (
            el.selected_item_unit.item_unit_id ==
              el2.selected_item_unit.item_unit_id &&
            index != index2
          ) {
            return true;
          }
          return false;
        });
        if (cek2.length > 0) {
          return true;
        }
        return false;
      });

      if (cek.length > 0) {
        this.$vs.notify({
          title: "Error",
          text: "Item is duplicate with same HU",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return false;
      }

      this.$vs.loading();

      this.$http
        .post("/api/sfa/v1/product-btwob-rules/store", {
          valid_from: this.validity.startDate
            ? moment(this.validity.startDate).format("YYYY-MM-DD")
            : null,
          valid_to: this.validity.endDate
            ? moment(this.validity.endDate).format("YYYY-MM-DD")
            : null,
          territory_id: this.selectedTerritory.id,
          unit_id: this.selectedUnit.id,
          company_id: this.CompanyID,
          item_unit: this.itemLines.map((el) => {
            return {
              item_unit_id: el.selected_item_unit.item_unit_id,
              unit_id: el.selected_item_unit.unit_id,
              qty: Number(el.qty),
            };
          }),
          qty_total: parseInt(this.qtySO),
          status: this.status
            ? this.status == true
              ? "Active"
              : "Inactive"
            : "Inactive",
          supplier_id: this.selectedSupplier.id,
        })
        .then((resp) => {
          // console.log(resp);
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Reservation has been added",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.handleBack();
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }

          this.$vs.loading.close();
        });
    },
    getTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/route-plan/territory", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritory = resp.data.records;
              // if (this.optionTerritory.length > 0) {
              //   this.selectedTerritory = this.optionTerritory[0];
              // } else {
              //   this.optionTerritory = [];
              //   this.selectedTerritory = {};
              // }
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getSupplier() {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/supplier", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionSupplier = resp.data.records;
              // if (this.optionSupplier.length > 0) {
              //   this.selectedSupplier = this.optionSupplier[0];
              // } else {
              //   this.optionSupplier = [];
              //   this.selectedSupplier = {};
              // }
            } else {
              this.optionSupplier = [];
              this.selectedSupplier = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getUnit() {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/product-btwob-rules/unit", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionalUnit = resp.data.records;
              // if (this.optionalUnit.length > 0) {
              //   this.selectedUnit = this.optionalUnit[0];
              // } else {
              //   this.optionalUnit = [];
              //   this.selectedUnit = {};
              // }
            } else {
              this.optionalUnit = [];
              this.selectedUnit = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    customLableTerritory({ code, name }) {
      return `${code} ${name}`;
    },
    customLableSupplier({ code, name }) {
      return `${code}-${name}`;
    },

    customLableUnit({ Name }) {
      return `${Name}`;
    },
    setStartEnd() {
      let valStart = this.length * this.page - this.length + 1;
      if (valStart > this.total) {
        valStart = 1;
      }
      if (this.total == 0) {
        valStart = 0;
      }
      let valEnd = this.length * this.page;
      if (valEnd > this.total) {
        valEnd = this.total;
      }
      if (this.totalSearch < this.total) {
        valEnd = this.totalSearch;
      }

      this.start = valStart;
      this.end = valEnd;
    },

    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("DD MMM YYYY");
      }
      return a;
    },
    addRow(index) {
      this.itemLines.push({
        option_items: [],
        selected_items: null,
        option_item_unit: [],
        // : null,
      });
      //check if item was selected in previous row then dont show that item in next row option it mean user never select same item in 2 row

      this.getOptionItemAvailable(this.itemLines.length);
    },
    removeRow(index) {
      this.itemLines.splice(index, 1);
    },
    onChangeItems(val, index) {
      let cek = this.itemLines.filter((el, i) => {
        if (el.selected_items) {
          if (el.selected_items.id == val.id && i != index) {
            return true;
          }
        }
        return false;
      });

      if (cek.length > 0) {
        this.itemLines[index].selected_items = null;
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Sales already add",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }
      // console.log("val", val);

      this.itemLines[index].selected_items = val;
    },
    customLableItems({ sku_code }) {
      return `${sku_code}`;
    },

    onSearchItems(search) {
      this.search = search;
      this.page = 1;
      this.getOptionItemAvailable();
    },
    SupplierInput() {
      this.itemLines = [
        {
          option_items: [],
          selected_items: null,
          option_item_unit: [],
          selected_item_unit: null,
          qty: [],
        },
      ];
      this.getOptionItemAvailable();
    },
  },
};
</script>
